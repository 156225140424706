import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';
import HeroBackground from '../../assets/images/hero-background.png';

const Hero = () => (
  <HeroContainer p={3} flexWrap="wrap">
    <HeroWrapper>
      <Styledh1>Advocaat Frank Reynaert</Styledh1>
      <Slogan>
        <IconLeft>
          <FontAwesomeIcon icon={faQuoteLeft} />
        </IconLeft>
        <SloganBody>Juridische stabiliteit bouwen.</SloganBody>
        <IconRight>
          <FontAwesomeIcon icon={faQuoteRight} />
        </IconRight>
      </Slogan>
    </HeroWrapper>
  </HeroContainer>
);

export default Hero;

const HeroContainer = styled(Flex)`
  align-items: center;
  background-image: url(${HeroBackground});
  background-position: center;
  background-size: cover;
  justify-content: center;
  min-height: 320px;
`;

const HeroWrapper = styled(Box)`
  margin: 40px 0;
`;

const Styledh1 = styled.h1`
  background-color: rgb(33, 37, 40);
  border-radius: 4px;
  box-shadow: 0px 6px 20px 0 rgba(0, 0, 0, 0.3);
  color: white;
  padding: 10px 40px;
  text-align: center;
`;

const Slogan = styled.div`
  border-radius: 4px;
  color: rgb(33, 37, 40);
  font-size: 2rem;
  margin-top: 60px;
  padding: 10px 20px;
  position: relative;
  text-align: center;
`;

const IconLeft = styled.span`
  display: block;
  text-align: left;
  height: 50px;
`;

const IconRight = styled.span`
  height: 50px;
  display: block;
  text-align: right;
`;

const SloganBody = styled.p`
  font-style: italic;
  padding: 0 30px;
`;
