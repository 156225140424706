import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import Footer from '../modules/footer/Footer';
import Home from '../modules/home/Home';
import Header from '../modules/navigation/Header';

const Index = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <Home />
    <Footer />
  </Provider>
);

export default Index;
