import React from 'react';
import { Box } from '@rebass/grid';

import Hero from './Hero';
import Actions from './Actions';

const Home = () => (
  <Box>
    <Hero />
    <Actions />
  </Box>
);

export default Home;
