import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faListUl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Choices from '../../assets/images/choices.svg';

const Actions = () => (
  <React.Fragment>
    <ActionsWrapper>
      <Centered>
        <Box width={[1, 1 / 3]} p={3}>
          <ActionBox className="services" to="/diensten">
            <Box p={2}>
              <ContactIcon icon={faListUl} />
            </Box>
            <Box p={2}>
              <p>
                bekijk de <Bigger>diensten</Bigger>
              </p>
            </Box>
          </ActionBox>
        </Box>
        <Box width={[1, 1 / 3]} p={3}>
          <ActionBox className="about" to="/over">
            <Box p={2}>
              <ContactIcon icon={faAddressCard} />
            </Box>
            <Box p={2}>
              <p>
                over advocaat <Bigger>Reynaert</Bigger>
              </p>
            </Box>
          </ActionBox>
        </Box>
        <Box width={[1, 1 / 3]} p={3}>
          <ActionBox className="contact" to="/contact">
            <Box p={2}>
              <ContactIcon icon={faEnvelope} />
            </Box>
            <Box p={2}>
              <p>
                stuur een
                <Bigger>bericht</Bigger>
              </p>
            </Box>
          </ActionBox>
        </Box>
      </Centered>
    </ActionsWrapper>
    <ImageWrapper p={3}>
      <TimelineImg src={Choices} alt="choices" />
    </ImageWrapper>
  </React.Fragment>
);

export default Actions;

const Centered = styled(Flex)`
  max-width: 1128px;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const ActionsWrapper = styled(Flex)`
  justify-content: center;
`;

const Bigger = styled.span`
  font-size: 2.2rem;
  display: block;
`;

const ContactIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  height: 100%;
`;

const ActionBox = styled(Link)`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 200px;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &.contact {
    background-color: #d26426;
    color: white;
  }

  &.services {
    background-color: #f5dbcd;
    color: rgb(33, 37, 40);
  }

  &.about {
    background-color: #e9ae8c;
    color: #bb5921;
  }

  &:hover {
    background-color: white;
    box-shadow: 0 4px 60px 0 rgba(182, 182, 182, 0.65), 0 4px 12px 0 rgba(182, 182, 182, 0.4);
    transform: scale(1.01);
    color: #d26426;
    transition: all 0.3s ease-in-out;
    z-index: 99;
  }
`;

const TimelineImg = styled.img`
  max-height: 300px;
  max-width: 90%;
`;

const ImageWrapper = styled(Box)`
  text-align: center;
  padding-top: 50px;
  @media (max-width: 639px) {
    display: none;
  }
`;
